import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";

const ENDPOINT = "/app/balance-order";

export async function createBalanceOrder(packageID: Learnlink.BalanceOrder.default.Order["package"]): Promise<Learnlink.Balance.default> {
  const balanceRes = await learnlinkAPI.axios.post<Learnlink.Balance.default>(
    `${ENDPOINT}/new/${packageID}`,
  );
  return balanceRes.data;
}
