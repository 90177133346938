























































































































import { computed, defineComponent, onMounted, PropType, reactive, ref, watch, watchEffect } from "@vue/composition-api";
import { useNotifier } from "@/providers/notifier";
import { getPricing, immediatePay } from "@/api/balanceService";
import { createBalanceOrder } from "@/api/balanceOrderService";

export default defineComponent({
  name: "TopUpBalanceDialog",
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    const price = ref(0);
    const loadingPrice = ref(true);
    const choices = [
      10,
      20,
      40,
      60
    ];
    const choice = ref(choices[0]);

    watchEffect(() => {
      emit("input", _value);
    });

    const refreshPricing = async () => {
      loadingPrice.value = true;
      const pricing = await getPricing(choice.value);
      price.value = pricing;
      loadingPrice.value = false;
    };
    watch(choice, refreshPricing);
    onMounted(refreshPricing);

    const notify = useNotifier();
    const vm = reactive({
      loading: false,
    });

    async function openImmediatePay(): Promise<void> {
      if (vm.loading || loadingPrice.value) {
        return;
      }
      vm.loading = true;
      try {
        const session = await immediatePay(choice.value);
        window.open(session.url, "_self")?.focus();
      }
      catch (e) {
        if (e instanceof Error) {
          notify({
            title: "Oops!",
            message: `Noe gikk galt: ${e.message}`,
            type: "error",
          });
        }
      }
      vm.loading = false;
    }

    async function makeOrder(): Promise<void> {
      if (vm.loading || loadingPrice.value) {
        return;
      }
      vm.loading = true;
      try {
        await createBalanceOrder(choice.value as 20);
        emit("close");
        emit("refresh");
      }
      catch (e) {
        if (e instanceof Error) {
          notify({
            title: "Oops!",
            message: `Noe gikk galt: ${e.message}`,
            type: "error",
          });
        }
      }
      vm.loading = false;
    }

    const changeChoice = (n: number) => {
      choice.value = n;
    };

    return {
      openImmediatePay,
      makeOrder,
      loadingPrice,
      vm,
      _value,
      choices,
      choice,
      price,
      computed,
      changeChoice
    };
  },
});
